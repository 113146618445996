<template>
  <div style="background:lightgrey;">
    <v-container>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
          <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
          <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <br />
      <v-row justify="space-around">
        <v-col cols="12" sm="12">
          <center>
            <v-btn color="primary" dark class="mb-2" @click="printReceipt()" style="margin-left:10px;">
              <v-icon>mdi-content-save</v-icon>PRINT Receipt
            </v-btn>
          </center>
        </v-col>
      </v-row>
      <v-card>
          <v-card-text>
            <div id="printonlyreceipt">
              <div class="pageHeight" style="width:auto; padding:4px;">
                <div style="position: relative;" class="printHeader">
             
             <div v-if="istemplateApplicable == true">
              
               <span v-html="rawhtmlcode"></span>
              
             </div>
             <div v-else>
               <div v-if="org.org_code == 'VU'">
                 <br/>
                 <div class="row">
                 <div class="column" style="width:20%">
                 <center>  <img style="margin-left:10%;height:60px;width:200px" :src="org.logo" /></center>
                 </div>
                 <div class="column" style="width:80%">
                   <div style="text-align: center; color: black">                    
                     <center >
                       <div class="text-center" style="font-size:18px;">
                         <b>Fees Receipt</b>
                       </div>
                     </center>
                   </div>
                 </div>
                 </div>
                 <br />
                 <hr>
                 <hr/>
                 <br />
               </div>
               <div v-else>
               <div style="position: absolute;left: 0">
                 <img style="height:90px;width:150px" :src="org.logo" />
               </div>
               <div style="text-align: center; color: blue">
                 <div
                   v-if="org.org_code == 'VIT'"
                   cols="12"
                   sm="10"
                   style="color:blue;"
                 >
                   <center>
                     <div class="text-center">
                       Bansilal Ramnath Agarwal Charitable Trust's
                     </div>
                     <div class="text-center" style="font-size:18px;">
                       <b>VISHWAKARMA INSTITUTE OF TECHNOLOGY, PUNE</b>
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >(An Autonomous Institute Affiliated to Savitribai
                         Phule Pune University)</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b>666, Upper Indira Nagar, Bibwewadi, Pune-411037</b>
                     </div>
                   </center>
                   <br />
                   <span style="float:right;color:black;"
                     >Issue.01: Rev No 0 Date. 16/03/2016 FF No. FF259</span
                   >
                   <br />
                 </div>
                 <div
                   v-else-if="org.org_code == 'PCCOE'"
                   cols="12"
                   sm="10"
                   style="color:blue;"
                 >
                   <center>
                     <div class="text-center">
                       PIMPRI CHINCHWAD EDUCATION TRUST's
                     </div>
                     <div class="text-center" style="font-size:18px;">
                       <b>PIMPRI CHINCHWAD COLLEGE OF ENGINEERING, PUNE</b>
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >(An Autonomous Institute Affiliated to Savitribai
                         Phule Pune University)</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >Sector No. 26, Pradhikaran, Nigdi, Pimpri-Chinchwad,
                         Maharashtra 411044</b
                       >
                     </div>
                   </center>
                   <br />
                   <span style="float:right;color:black;"
                     >Issue.01: Rev No 0 Date. 16/03/2016 FF No. FF259</span
                   >
                   <br />
                 </div>
                 <div
                   v-else-if="org.org_code == 'VCACS'"
                   cols="12"
                   sm="10"
                   style="color:blue;"
                 >
                   <center>
                     <div class="text-center">
                       Bansilal Ramnath Agarwal Charitable Trust's
                     </div>
                     <div class="text-center" style="font-size:18px;">
                       <b
                         >VISHWAKARMA ARTS COMMERCE AND SCIENCE JUNIOR
                         COLLEGE</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b>(Affiliated to Maharashtra State Board, Pune)</b>
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <br />
                     </div>
                   </center>
                   <div>
                     <span style="float: left;margin-left:10%;font-weight:500;"
                       >Index No. J.11.11.087
                     </span>
                     <span style="float: left;margin-left:50%;font-weight:500;"
                       >U DISE No:-27251700631</span
                     >
                   </div>
                   <br />
                 </div>
                 <div
                   v-else-if="org.org_code == 'VIIT'"
                   cols="12"
                   sm="10"
                   style="color:blue;"
                 >
                   <center>
                     <div class="text-center">BRACT's</div>
                     <div class="text-center" style="font-size:18px;">
                       <b
                         >VISHWAKARMA INSTITUTE OF INFORMATION TECHNOLOGY,
                         PUNE</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >(An Autonomous Institute Affiliated to Savitribai
                         Phule Pune University)</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b>(Approved by A.I.C.T.E. New Delhi)</b>
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >NAAC Accredited With 'A' Grade,An ISO 9001:2015
                         Certified Institute</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b
                         >S.No.3/4,Kondhwa Bk.,Pune-411048.MAHARASHTRA,INDIA</b
                       >
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b>E-mail:director@viit.ac.in Website:www.viit.ac.in</b>
                     </div>
                   </center>
                 </div>
                 <div v-else cols="12" sm="10" style="color:blue;">
                   <div style="display:inline;" v-if="org.state || org.statecode || org.gst_no || org.sac_code">
                       <div style="float:left; width:75%; display:inline-block;">
                           <div class="text-center">{{ org.trust_name }}'s</div>
                           <div class="text-center" style="font-size:18px;">
                             <b>{{ org.org_detailed_name }}</b>
                           </div>
                           <div class="text-center" style="font-size:14px;">
                             <b>{{ org.address }}</b>
                           </div>
                       </div>
                       <div style="float:right; width:25%; display:inline-block;">
                           <div class="text-left" v-if="org.state" style="font-size:14px;"><b>State : </b>{{ org.state }}</div>
                           <div class="text-left"  v-if="org.statecode" style="font-size:14px;"><b>State Code : </b>{{ org.statecode }}</div>
                           <div class="text-left"  v-if="org.gst_no" style="font-size:14px;"><b>GST No. : </b>{{ org.gst_no }}</div>
                           <div class="text-left"  v-if="org.sac_code" style="font-size:14px;"><b>SAC Code : </b>{{ org.sac_code }}</div>
                       </div>
                       <br>
                       <br>
                   </div>
                   <center v-else>
                     <div v-if="org.trust_name!=' '" class="text-center">{{ org.trust_name }} 's</div>
                     <div class="text-center" style="font-size:18px;">
                       <b>{{ org.org_detailed_name }}</b>
                     </div>
                     <div class="text-center" style="font-size:14px;">
                       <b>{{ org.address }}</b>
                     </div>
                   </center>
                 </div>
               </div>
               <div v-if="org.org_code == 'VIT' || org.org_code == 'PCCOE'">
                 <hr
                   style="height:3px; background:black;border-radius: 50px;"
                 />
                 <span style="float:left;"
                   ><b>Approved By A.I.C.T.E. New Delhi</b></span
                 >
                 <span style="float:right;"
                   ><b>Identification No.PU/PN/Engg./061/1984</b></span
                 >
                 <br />
                 <hr
                   style="height:3px; background:black;border-radius: 50px;"
                 />
                 <br />
               </div>
               <div
                 v-else-if="org.org_code == 'VIIT' || org.org_code == 'VCACS'"
               >
                 <hr
                   style="height:1px; background:black;border-radius: 50px;"
                 />
                 <hr
                   style="height:1px; background:black;border-radius: 50px;"
                 />
                 <br />
               </div>
               <div v-else>
                 <br /><br />
                 <hr
                   style="height:1px; background:black;border-radius: 50px;"
                 />
                 <hr
                   style="height:1px; background:black;border-radius: 50px;"
                 />
                 <br />
               </div>
               </div>
               <div v-if="org.org_code == 'VU'">
                   <div cols="12" sm="12">
                     <div style="display:inline;">
                       <div style="float:left; width:100%; display:inline-block;">
                         <b>PRN. :</b> {{ rec.grno }}
                       </div>
                     </div>
                   </div>
                   <div cols="12" sm="12">  
                     <div style="display:inline;">
                       <div style="float:left; width:50%; display:inline-block;">
                         <b>Receipt No. :</b>
                           <span v-if="isreceiptnoisnumbersequenceonly == 'true'">
                             {{rec.receipt_no_sequence}}
                           </span>
                           <span v-else>
                             {{rec.rec_no }}
                           </span>
                       </div>
                     
                       <div style="float:right; width:50%; display:inline-block;">
                         <b> Receipt Date. :</b>{{ rec.rec_date }}
                       </div>
                     </div>
                   </div>
                   <div cols="12" sm="12">  
                     <div style="display:inline;">
                       <div style="float:left; width:100%; display:inline-block;">
                         <b>Student Name :</b>{{rec.name}}
                         </div>
                     </div>
                   </div>
                   <div cols="12" sm="12">  
                     <div style="display:inline;">
                       <div style="float:left; width:50%; display:inline-block;">
                         <b>Email :</b>{{rec.email}}
                         
                       </div>
                       <div style="float:right; width:50%; display:inline-block;">
                         <b>Mobile :</b>{{ rec.mobileno }}
                       </div>
                     </div>
                   </div>
                   <div cols="12" sm="12">  
                     <div style="display:inline;">
                       <div style="float:left; width:50%; display:inline-block;">
                         <b>Faculty  :</b>{{rec.department}}
                         
                       </div>
                       <div style="float:right; width:50%; display:inline-block;">
                         <b>Program :</b>{{ rec.prog }}({{rec.year}})
                       </div>
                     </div>
                   </div>
                   <div cols="12" sm="12">  
                     <div style="display:inline;">
                       <div style="float:left; width:50%; display:inline-block;">
                         <b>Academic Year  :</b>{{rec.ay}}
                         
                       </div>
                       <div style="float:right; width:50%; display:inline-block;">
                         <b>Admission Year :</b>{{ rec.adission_year }}
                       </div>
                     </div>
                   </div>
                 <br />
                 <br />
                 <br />
                 <br />
               </div>
               <div v-else>
                 <div>
                   <div cols="12" sm="12">
                     <center>
                       <b>Receipt No. :</b>
                       <span v-if="isreceiptnoisnumbersequenceonly == 'true'">
                         {{rec.receipt_no_sequence}}
                       </span>
                       <span v-else>
                         {{rec.rec_no }}
                       </span>
                     </center>
                   </div>
                 </div>
                 <br />
                 <div style="display:inline;">
                   <div
                     v-if="org.org_code != 'VCACS'"
                     style="float:left; width:33%; display:inline-block;"
                   >
                     <b>PRN. :</b> {{ rec.grno }}
                   </div>
                   <div
                     v-else
                     style="float:left; width:33%; display:inline-block;"
                   >
                     <b>Member ID :</b> {{ rec.grno }}
                   </div>
                   <div v-if="rec.dteno" style="float:left; width:33%; display:inline-block;">
                     <center>
                       <b>DTE Application No. : </b>{{ rec.dteno }}
                     </center>
                   </div>
                   <div v-else-if="rec.srn" style="float:left; width:33%; display:inline-block;">
                     <center>
                       <b>SRN : </b>{{ rec.srn }}
                     </center>
                   </div>
                   <div v-else style="float:left; width:33%; display:inline-block;"></div>
                   <div style="float:right; width:33%; display:inline-block;">
                     <b> Receipt Date. :</b>{{ rec.rec_date }}
                   </div>
                 </div>
                 <br />
                 <br />
                 <div>
                   <div cols="12" sm="12">
                     <center>
                       Received with thanks fees for the year {{ rec.ay }} from
                       <br />
                       <b>{{ rec.full_name }}</b>
                       <br />
                       towards
                       <span v-if="org.org_code == 'VIIT'">provisional</span>
                       admission to {{ rec.year }} - {{ rec.prog }}
                       <br />
                       Under {{ rec.quota }} - {{ rec.category }} as per
                       following details:
                     </center>
                   </div>
                 </div>
               </div>
               <center>
                <div style="height:70px;"></div>
                 <table style="width: 80%;padding-top:20px;">
                   <tr>
                     <th align="left">
                       <center>Sr.No</center>
                     </th>
                     <th>Particulars of the Fees</th>
                     <th align="left">Amount</th>
                   </tr>

                   <tr v-for="item in component_list" :key="item.component">
                     <td align="center">{{ item.sr_no }}</td>
                     <td align="left">{{ item.component }}</td>
                     <td align="right">{{ item.amount }}</td>
                   </tr>

                   <tr>
                     <td colspan="2" align="right"><b>Total: </b></td>
                     <td align="right">
                       <b>{{ component_total_amount }}</b>
                     </td>
                   </tr>
                 </table>
               </center>
               <br />
               <div v-if="org.org_code != 'VU'">
                 <b
                   v-if="org.org_code != 'VIIT'"
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   >Amount in Words:&nbsp;{{ words }}</b
                 >
               </div>  
               <div
                 v-if="
                   org.org_code != 'VIIT' &&
                     outstanding.isOutStandingApplicable
                 "
               >
                 <br />
                 <hr />
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-if="outstanding.dueAmount <= 0"
                 >
                   Amount Due :&nbsp;Nil
                 </b>
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-else
                 >
                   Amount Due :&nbsp; {{ outstanding.dueAmount }}
                 </b>
                 <br />
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-if="outstanding.dueAmount <= 0"
                 >
                   Amount Due in Words:&nbsp;Nil
                 </b>
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-else
                 >
                   Amount Due in Words:&nbsp;{{ outstanding.dueamountinword }}
                 </b>
                 <br /><span
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;color:#dedede;"
                   >(Due Amount subject to change depending upon change in
                   student fees master)</span
                 >
               </div>
               <br /><br />
               <div style="width:100%;height:200px;">
                 <div v-if="org.org_code == 'VU'">
                   <div cols="12" sm="6" style="float:left;margin-left:20px;">
                     <img style="width:150px;margin-right:50px;" :src="stamp" />
                     <br />
                     <b>Seal</b>
                   </div>
                 </div>  
                 <div v-else>
                   <div cols="12" sm="6" style="float:left;margin-left:20px;">
                     <img style="width:150px;margin-right:50px;" :src="stamp" />
                     <br />
                     <b>Seal of College</b>
                   </div>
                 </div>  
                 <div
                   v-if="org.org_code == 'VIT' || org.org_code == 'PCCOE' || org.org_code == 'VU'"
                   cols="12"
                   sm="6"
                   style="float:right;margin-right:20px;"
                 >
                   <img style="width:150px;height:150px;" :src="sign" />
                   <br />
                   <b>Signature of Receiver</b>
                 </div>
                 <div v-else cols="12" sm="6" style="float:right;">
                   <br /><br />
                   <img style="width:200px; margin-right:50px;" :src="sign" />
                   <br />
                   <b>Signature of Receiver</b>
                 </div>
               </div>
               <hr />
               <br />
               <div v-if="org.org_code == 'VU'">
                   <div cols="12" sm="12" style="float:center;margin-left:100px;">
                     <h5>DETAILS OF PAYMENT MADE TOWARDS FEES DEPOSITED</h5>
                   </div>
                   <br>
               </div>  
               <center>
                 <table style="width: 80%">
                   <tr>
                     <th>Payment Mode</th>
                     <th>Drawn On</th>
                     <th>DDNo./ChequeNo./Transaction No.</th>
                     <th>Date</th>
                     <th>
                       Amount (Rs.)
                     </th>
                   </tr>
                   <tr v-for="item in trans_list" :key="item.tid">
                     <td>{{ item.mode }}</td>
                     <td>{{ item.bank }}</td>
                     <td>{{ item.tid }}</td>
                     <td>{{ item.date }}</td>
                     <td>{{ item.amount }}</td>
                   </tr>
                 </table>
               </center>
               <br />
               <b
                 v-if="org.org_code == 'VIIT'"
                 style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                 >Amount in Words:&nbsp;{{ words }}</b
               >
               <div
                 v-if="
                   org.org_code == 'VIIT' &&
                     outstanding.isOutStandingApplicable
                 "
               >
                 <br />
                 <hr />
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-if="outstanding.dueAmount <= 0"
                 >
                   Amount Due :&nbsp;Nil
                 </b>
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-else
                 >
                   Amount Due :&nbsp; {{ outstanding.dueAmount }}
                 </b>
                 <br />
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-if="outstanding.dueAmount <= 0"
                 >
                   Amount Due in Words:&nbsp;Nil
                 </b>
                 <b
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;"
                   v-else
                 >
                   Amount Due in Words:&nbsp;{{ outstanding.dueamountinword }}
                 </b>
                 <br /><span
                   style="width: 80%;margin-left: 70px; word-wrap: break-word;color:#dedede;"
                   >(Due Amount subject to change depending upon change in
                   student fees master)</span
                 >
               </div>
               <br />
               <div v-if="org.org_code == 'VU'">
                   <div cols="12" sm="12" style="float:center;margin-left:50px;">
                     <b>Amount in Words (Rs.):&nbsp;{{ words }}</b>
                   </div>
                   <br>
               </div> 
               <br/> 
               <div style="float:left; display:inline-block;">
                 Remark: {{ rec.remark }}
               </div>
               <br />
               <div v-if="org.org_code == 'VIIT'">
                 <div cols="12" sm="12">
                   <span style="float:right;">Student/Guardian Signature</span>
                   <br /><br />
                   <br />
                   <b v-if="isreceiptgenratername"
                     >Receipt Generated By &nbsp;: &nbsp;{{
                       receiptgenname
                     }}</b
                   >
                   <span style="color:red; font-weight:bold; ">
                     <center
                       style="background-color:#F1F1F1; border:1px solid; border-radius:8px; padding:10px;"
                     >
                       NOTE : This is Computer generated Receipt.
                     </center>
                   </span>
                 </div>
               </div>
               <div v-if="org.org_code != 'VIT' && org.org_code != 'VIIT'">
                 <div cols="12" sm="12">
                   <br />
                   <b v-if="isreceiptgenratername"
                     >Receipt Generated By &nbsp;: &nbsp;{{
                       receiptgenname
                     }}</b
                   >
                   <br />
                   <span style="color:red; font-weight:bold; ">
                     <center
                       style="background-color:#F1F1F1; border:1px solid; border-radius:8px; padding:10px;"
                     >
                       NOTE : This is Computer generated Receipt and requires
                       no signature
                     </center>
                   </span>
                 </div>
               </div>
               <br />
               <hr
                 style="background:black !important; height:5px !important;"
                 v-if="org.org_code == 'VCACS'"
               />
               <table
                 v-if="org.org_code == 'VCACS'"
                 style="border: none !important;width:100%;font-family: Arial, Helvetica, sans-serif;"
               >
                 <tr>
                   <td style="border: none !important;">
                     <center>
                       <span style="font-size:18px; font-weight:500;"
                         >Sr.No. 3/6, Laxminagar, Kondhwa (Bk), VIIT campus
                         Pune-411048</span
                       >
                       <br />
                       <span style="font-size:18px; font-weight:500;"
                         >Ph.No. : 7888016565/7880076565 | E-mail :
                         vacsjc@gmail.com | Website : www.vcacs.ac.in</span
                       >
                     </center>
                   </td>
                 </tr>
               </table>
             </div>
           </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
const CryptoJS = require("crypto-js")
import "@/assets/css/styles.css";
export default {
  data: () => ({
    error: "",
    snackbar_msg: "",
    color: "",
    snackbar: false,
    rec_no: "",
    component_list: [],
    org: [],
    rec: [],
    trans_list: [],
    component_total_amount: 0,
    words: "",
    stamp: null,
    sign: null,
    outstanding: [],
    isreceiptgenratername: false,
    receiptgenname: "Na",
    rawhtmlcode: "",
    istemplateApplicable: false,
    isreceiptnoisnumbersequenceonly : false,
    totalbankamount : 0,
  }),
  components: {},
  mounted() {
    this.rec_no = this.$route.params.rec_no;
    if (!this.rec_no) 
        this.rec_no = this.decrypt(this.$route.query.rec_no);
    this.viewreceipt();
  },
  methods: {
    printReceipt() {
      var content = document.getElementById("printonlyreceipt").innerHTML;
      var mywindow = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      location.reload();
      document.body.innerHTML = mywindow;
    },

    decrypt (src) {
      const passphrase = '987654'
      const bytes = CryptoJS.AES.decrypt(src, passphrase)
      const originalText = bytes.toString(CryptoJS.enc.Utf8)
      return originalText
    },

    
    showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
    },

    viewreceipt(){
      const data = {
        rec_no: this.rec_no,
      };
      axios
        .post("/appLearnerAdmission/admissionreceipt", data)
        .then((res) => {
          if (res.data.status == "SUCCESS") {
            this.rec = res.data.receipt;
            this.isreceiptnoisnumbersequenceonly = res.data.isreceiptnoisnumbersequenceonly;
            this.trans_list = res.data.trans_list;
            this.component_list = res.data.component_list;
            this.component_total_amount = res.data.component_total_amount;
            this.words = res.data.words;
            this.org = res.data.org;
            this.stamp = res.data.stamp;
            this.sign = res.data.sign;
            this.isreceiptgenratername = res.data.isreceiptgenratername;
            this.outstanding = res.data.outstanding;
            this.isreceiptgenratername = res.data.isreceiptgenratername;
            this.receiptgenname = res.data.receiptgenname;
            this.rawhtmlcode=res.data.output;
            this.istemplateApplicable=res.data.istemplateApplicable;
            this.totalbankamount = res.data.totalbankamount;
          }else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something Went Wrong...");
        });
    },
  },
};
</script>
<style scoped>
.text-left {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 0px !important;
}
.text-left-head {
  font-size: 14px;
  font-weight: bold;
}

input,
textarea {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
h6 {
  font-size: 1em;
  font-family: "Times New Roman", Times, serif ";";
}
input[type="text"] {
  padding: 4px;

  border: none;
}
table {
  border-collapse: collapse;

  font-size: 12px;
  width: 80%;
}

table,
th,
td {
  border: 1px solid black;
}
th {
  height: 30px !important;
  text-align: center !important;
}
th,
td {
  padding:2px;
}
.font-text {
  font-family: "Times New Roman";
}
#printonlyreceipt {
  display: block;
}
#noprint {
  display: block;
}
.pageHeight {
  height: auto;
}

.photo{
    border: 1px solid black !important;
    height: 130px;
    width: 120px;
    margin: 10px;
    padding: 20px;
    text-align: center;
}
@media print {
  #printonlyreceipt {
    display: block;
    font-size: 10px;
  }
  @page {
    size: letter;
  }
  #noprint {
    display: none;
  }
  .pageHeight {
    height: 230mm;
  }

  @page {
    margin-left: 2.1cm;
    margin-right: 1cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    counter-increment: page;
    content: counter(page);
  }
  body {
    font-size: 15px !important;
  }

  a[href] {
    display: none !important;
  }
  button {
    display: none !important;
  }
  input[type="submit"],
  input[type="button"] {
    display: none !important;
  }
  hr {
    border: none !important;
  }
  input[type="text"] {
    border: none !important;
  }
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
</style>
